@import 'styles/variables';

.footWrapper {
  width: 100%;
  text-align: left;
  border-radius: 0 !important;
  border: none;
  background: $secondary-dark-color;
  color: #fff;
  .footerTop {
    min-height: 195px;
    background: $secondary-color;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 64px;
      color: $primary-light-color;
    }
  }
  .footer {
    padding: 100px 20px 50px 20px;
  }
  .logo {
    text-align: center;
    img {
      width: 150px;
      margin-bottom: 10px;
    }
  }
  .footerMenu {
    a {
      margin-right: 5px;
      font-size: 16px;
    }
  }

  .footerDetails {
    p {
      margin-bottom: 5px;
    }
  }

  .footerAnchor {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: $primary-color;
  }

  .copyRight {
    height: 84px;
    background: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: #c2c2c2;
    }
  }

  .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $secondary-light-color;
  }

  .getAppText {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $secondary-light-color;
    margin: 0;
  }
}
