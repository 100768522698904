@import 'styles/variables';
.newLifeCard {
  max-width: 316px;
  padding: 0px 10px;
  margin: auto;
  @media only screen and (min-width: $screen-lg) {
    margin: 0 20px;
  }
  .headingText {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $primary-dark-color;
  }
  .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: $secondary-dark-color;
  }
  .circleImgWrapper {
    width: 70px;
    height: 70px;
    background: rgba(140, 89, 226, 0.15);
    border-radius: 20px;
  }
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  height: 100%;
  padding: 5px 0;
}
