@import 'styles/variables';

.userLayout {
  background-color: $white;
  overflow: hidden;
  position: relative;
}

.header {
  z-index: 2;
  width: 100%;
  background-color: transparent !important;
  position: fixed;
  padding: 0 20px;
  height: $header-height;
}

.header.authHeader {
  background-color: white !important;
}

.content {
  z-index: 1;
  position: relative;
}
