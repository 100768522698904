@import 'styles/variables';

/*
  # Hero Container styles
*/

.hero__image {
  background-image: url('/assets/about.png');
  height: 750px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  justify-content: center;
}

.whyNewLife__text__header {
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.whyNewLife__text__top {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: $primary-dark-color;
}

.whyNewLife__text__bottom {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: $secondary-dark-color;
  margin-top: 24px;
  white-space: pre-wrap;
}
