@import 'styles/variables';
.header {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  a {
    justify-content: center;
    align-self: center;
    display: flex;
  }
}

.logo {
  max-width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.signIn__btn {
  height: 48px;
  width: 134px;
}

.signUp__btn {
  background: transparent;
  box-shadow: none;
  width: 120px;
}

.signUp__btn__text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 32px !important;
  color: #ffffff !important;
}

.list {
  right: 0px;
  background: $white;
  width: 130px;
  max-width: 80vw;
  position: absolute;
  border-radius: 10px;
  margin-top: 0.8em;
  z-index: 5;
  margin-right: 2px;
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.1);
}

.list:after {
  bottom: 100%;
  left: 82%;
  border: solid transparent;
  content: ' ';
  height: 0 !important;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: $white;
  border-width: 8px;
  margin-left: -8px;
  transition: all 0.5s ease-in;
}

.text {
  line-height: 35px;
  font-weight: 480;
  margin-bottom: 0 !important;
  padding: 5px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  &:hover {
    background: $primary-color 0%;
    box-shadow: none;
    color: white;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;
  }
}

.nav {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}

.nav.authNav {
  color: #1c2848 !important;
}
