@import 'styles/variables';

/*
  # Hero Container styles
*/

.hero__image {
  background-image: url('/assets/HeroImage.png');
  height: 750px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  justify-content: center;
}

.hero__text__header {
  text-align: left;
  margin: 100px auto 0 auto;
  color: white;
  width: 100%;
  max-width: 1200px;
}

.hero__text__top {
  color: $white !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
}

.hero__text__bottom {
  color: $white !important;
  white-space: pre-wrap;
  font-size: 56px;
  font-weight: 700;
  line-height: 75px;
}

.hero__bottom__image {
  width: 1049.9px;
  height: 844px;
  max-width: 100%;
  position: relative;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  object-fit: contain;
}

/*
  # WhyNewLife Container styles
*/

.whyNewLife {
  min-height: 1280px;
  background: url('/assets/MainElipseRight.png') right top 10% / 18% no-repeat,
    url('/assets/MainElipseLeft.png') left bottom 10% / 15% no-repeat, #ffffff;
  position: relative;
  padding: 0 20px;
}

.whyNewLife__wrap {
  padding: 125px 0;
  padding-top: 62px;
  @media screen and (max-width: 768px) {
    padding-top: 75px;
  }
}

.whyNewLife__text__header {
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.whyNewLife__text__top {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: $primary-dark-color;
}

.whyNewLife__text__bottom {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: $secondary-dark-color;
  margin-top: 24px;
  white-space: pre-wrap;
}

/*
  # NewLife Container styles
*/

.newLifes {
  // background: #fcf9ec;
}

.newLifes__wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.newLifes__colWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/*
  # howWeDoIt Container styles
*/
.pursuing__health {
  padding-top: 100px;
}

.pursuing__health__image {
  width: 100%;
  position: relative;
  object-fit: contain;
  max-width: 1000px;
  margin: 0 auto;
  display: block;
}

.howWeDoIt {
  background: #f8f8f8;
  position: relative;
  padding: 0 20px;
}

.howWeDoIt__wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 30px;
}

/*
  # trackCelebrate Container styles
*/

.trackCelebrate {
  background: $white;
  position: relative;
  padding: 0 20px;
}

.dot {
  width: 10px;
  height: 10px;
  background: #00abc2;
  border: 3px solid #00abc2;
  border-radius: 10px;
}

.trackCelebrate__timeline_title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #007b8c;
  margin: 10px;
}

.trackCelebrate__timeline__descCon {
  width: 405px;
  max-width: 100%;
  height: 195px;
  background: #eef8f8;
  border-radius: 16px;
  padding: 20px;
}

.trackCelebrate__wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 170px;
  padding-bottom: 30px;
}

.trackCelebrate__img {
  object-fit: contain;
}

/*
  # HeadingText Component styles
*/

.headingText__text__header {
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.headingText__text__top {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: #1c2848;
  white-space: pre-wrap;
  margin-bottom: 24px;
}

.headingText__text__bottom {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #5d5d5d;
  white-space: pre-wrap;
}

/*
  # fewWords Container styles
*/

.fewWords {
  background: #fcf9ec;
  position: relative;
  padding: 0 20px;
}

.fewWords__wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 70px;
}

.dot__wrap {
  width: 16px;
  height: 16px;
  background: #eef8f8;
  border: 2px solid #00abc2;
  border-radius: 10px;
}

.dot__select {
  width: 16px;
  height: 16px;
  background: #00abc2;
  border: 3px solid #00abc2;
  border-radius: 10px;
}

/*
  # faqs Container styles
*/

.faqs {
  background: $white;
  position: relative;
  padding: 0 20px;
}

.faqs__wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 100px;
}

.faqs__text__top {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #1c2848;
  margin-bottom: 16px;
}
.faqs__text__bottom {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #5d5d5d;
}
