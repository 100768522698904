/*
  # faq Card styles
*/

.faq {
  position: relative;
  align-items: flex-start;
  padding: 41px 48px;
  max-width: 1440px;
  background: #ecf4fe;
  border-radius: 24px;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1c2848;
    margin: 0;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #5d5d5d;
  }
  .content__parent {
    height: 0px;
    margin-top: 0px;
    transition: all 0.5s ease;
    overflow: hidden;
  }
  .open__button {
    border: unset;
    background: unset;
  }
}

/*
  # review Card styles
*/

.review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 57px 64px 32px;
  gap: 24px;
  width: 540px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 14px 32px rgba(0, 0, 0, 0.12);
  border-radius: 24px 24px 24px 0px;
  margin: auto;

  .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #00525d;
  }

  .details {
    margin: 0;
  }

  .name {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #007b8c;
  }
}
