@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/variables';
* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
  color: $text-color;
  line-height: normal !important;
  font-size: 16px;
  ul {
    list-style: none;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
  }
  a {
    text-decoration: none;
  }
  p {
    color: $text-color;
    font-weight: 400;
  }

  a.active p {
    font-weight: 700;
  }
  #root {
    height: 100%;
    table tr td:nth-last-child(1),
    table tr th:nth-last-child(1) {
      width: 15%;
    }
    .unselectable {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  $alignVariables: (center, left, right, justify);
  @each $alignValue in $alignVariables {
    .text-#{$alignValue} {
      text-align: #{$alignValue};
    }
  }
  .cover-image {
    object-fit: cover;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

//* slick-dots is defined from react-slick.
.slick-dots {
  bottom: -45px;
}

// Styling Radio Button
.my-radio-button.ant-radio-button-wrapper-checked,
.my-radio-button.ant-radio-button-wrapper-checked:hover {
  background: linear-gradient(167.96deg, #00abc2 0%, #49bc8d 100%);
  color: #ffffff;
  border-color: #49bc8d !important;
}

// TAILWIND CUSTOM CSS

@layer components {
  .primaryBtn {
    @apply bg-gradient-to-b from-[#00abc2] to-[#49bc8d] rounded-full py-2 px-6 h-12;
  }
  .bannerCaption {
    @apply font-bold text-5xl leading-10 text-white;
  }

  .stepFormTitle {
    @apply text-2xl font-normal leading-[56px] text-center w-full mb-8 text-secondaryDark;
  }
}

.stripeInput {
  border-bottom: 1px solid #e0e0e0;
}

// Custom css for place holder

.customInput .ant-input::placeholder {
  color: #fff; /* change this to the desired color */
  opacity: 0.65;
}

// Slider arrow change

.habitSlider .slick-prev:before,
.habitSlider .slick-next:before {
  color: #00abc2;
}

//

.testiSlider .slick-slide {
  // margin: 15px 0;
  filter: blur(2px);
}

.testiSlider .slick-slide.slick-active {
  // margin: 15px 0;
  filter: blur(0px);
}

.testiSlider .slick-list {
  height: 600px !important;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: black;
}

.datePicker-popup {
  width: 820px;
  padding: 0px 30px;
  .ant-picker-panel-container {
    box-shadow: none;
  }
  .ant-picker-date-panel {
    width: 100% !important;
  }
  .ant-picker-footer {
    display: none;
  }

  .ant-picker-header {
    width: 100% !important;
    border: none;
    border-radius: 6px;

    background: #f2f8fe;
  }
  .ant-picker-body {
    width: 100% !important;

    table {
      width: 100% !important;

      height: 300px;
      padding: 10px;
    }
  }
  .ant-picker-cell-inner {
    width: 100%;
  }

  .ant-picker-cell-disabled {
    .ant-picker-cell-inner {
      background: #ffffff;
    }
  }
}
