//COLORS
$primary-light-color: #5cc9d8;
$primary-color: #00abc2;
$primary-dark-color: #1c2848;
$secondary-light-color: #edeef0;
$secondary-color: #1c2848;
$secondary-dark-color: #141d34;
//CONSTANT COLORS
$white: #ffffff;
$black: #000000;
$yellow: #fccb1a;
$dark-yellow: #f7c531;
$green: #5f9393;
$grey: #e0e0e0;
$light-grey: #f6f9f9;
$dark-grey: #474747;
$red: #fe4f60;
$white-smoke: #eff4f4;
//Z-INDEX
$base-z-index: 10;
$z-index-max: 9999;
$z-index-1: $base-z-index * 1;
$z-index-2: $base-z-index * 2;
$z-index-3: $base-z-index * 3;
//BORDER RADIUS
$base-border-radius: 5px;
$border-radius-1: $base-border-radius * 1;
$border-radius-2: $base-border-radius * 2;
$border-radius-3: $base-border-radius * 4;
$border-radius-25: 25%;
$border-radius-50: 50%;
$border-radius-75: 75%;
$border-radius-100: 100%;
// SCREEN
$screen-xs: 20em; // 320px
$screen-sm: 40em; // 640px
$screen-md: 48em; // 768px
$screen-lg: 64em; // 1024px
$screen-xl: 80em; // 1280px
$screen-xxl: 90em; // 1440px
// ANTD SCREEN
$antd-screen-sm: 36em; // 640px
$antd-screen-md: 48em; // 768px
$antd-screen-lg: 62em; // 992px
$antd-screen-xl: 75em; // 1200px
$antd-screen-xxl: 100em; // 1660px
//GRADIENT SAMPLE
$gradient-1: linear-gradient(
  135deg,
  rgba(255, 199, 35, 1) 0%,
  rgba(255, 57, 79, 1) 33%,
  rgba(209, 23, 204, 1) 66%,
  rgba(152, 18, 245, 1) 100%
);
//TEXT COLOR
$text-heading: #2c2f3c;
$text-color: #6e768a;
$text-off-heading-color: #999;

//INPUT-BOX-SHADOW
$input-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;

//HEADER HEIGHT
$header-height: 80px !important;
