@import 'styles/variables';

.post {
  padding: 20px 0;
}

.wrapper {
  position: relative;
  max-width: 458px;
  .paragraphContainer {
    max-width: 100%;
    line-height: 200%;
    margin-top: 30px;
    position: relative;
    .divider {
      position: absolute;
      left: -20px;
      top: 8px;
      height: 52px;
      border-left: 3px solid #00abc2;
      border-radius: 10px;
    }
  }
}

.divWrapper {
  width: 400px;
  margin: 0 !important;
  height: 100%;
  .imageWrapper {
    position: relative;
    max-width: 100%;
    .image {
      border-radius: 50px;
      object-fit: contain;
      width: 100%;
    }
  }
  .iconWrapper {
    position: absolute;
    border-radius: 50%;
    width: clamp(66px, 12vw, 100px);
    height: clamp(66px, 12vw, 100px);
    border: 1px solid #e6e6e6;
    background-color: $white;
    z-index: 3;
    right: -12%;
    bottom: 5%;
    @media only screen and (max-width: $antd-screen-sm) {
      right: -5%;
    }
  }
}

.button {
  width: 188px;
  height: 48px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: #1c2848;
}
