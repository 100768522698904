@import 'styles/variables';

.outlined__button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  user-select: none;

  width: 296px;
  height: 48px;

  border: 2px solid #00abc2;
  border-radius: 29px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $primary-color;
    margin: 0;
  }
}

.app__button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  user-select: none;

  width: 214px;
  height: 48px;

  background: linear-gradient(167.96deg, #00abc2 0%, #49bc8d 100%);
  box-shadow: 0px 8px 11px rgba(61, 186, 151, 0.4);
  border-radius: 28px;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $white;
    margin: 0;
  }
}
